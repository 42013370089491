var klaroConfig = {
  elementID: 'klaro',
  storageMethod: 'cookie',
  cookieExpiresAfterDays: 365,
  privacyPolicy: '/datenschutz',
  default: false,
  mustConsent: false,
  hideDeclineAll: false,
  acceptAll: true,

  translations: {
    de: {
      consentModal: {
        description:
          'Hier können Sie einsehen und anpassen, welche Information wir über Sie sammeln. ',
      },
      consentNotice: {
        description: 'Wir schätzen Ihre Privatsphäre. Daher bitten wir Sie hiermit um Erlaubnis, die folgenden Technologien zu verwenden: {purposes}. Sie können Ihre Einwilligung später zu jederzeit ändern/widerrufen (Cookie-Einstellungen)',
        learnMore: 'mehr Informationen',
      },
      acceptSelected: 'Auswahl Übernehmen',
      googleAnalytics: {
        description:
          'Open-Source-Webanalyse-Tool'
      },
      purposes: {
        analytics: 'Besucher-Statistiken',
        security: 'Sicherheit',
        advertising: 'Anzeigen von Werbung',
        styling: 'Styling',
        video: 'Abspielen von Videos',
        recaptcha: 'Vermeidung von Spam',
        map: 'Kartendienst',
        fonts: 'Schriftdarstellung',
        cookie: 'Speicherung von Einstellungen dieser Anwendung',
      },
      reCaptcha: {
        description: 'Externes Tool zur Formularverarbeitung (Anbieter: Google)',
      },
      youtube: {
        description: 'Externer Videoplayer (Anbieter: Youtube)',
      },
      googleMaps: {
        description: 'Kartendarstellung (Anbieter: Google)',
      },
    },
    en: {
      consentModal: {
        description:
          'Here you can see and customize the information that we collect about you. ',
      },
      consentNotice: {
        description: 'Wir schätzen Ihre Privatsphäre. Daher bitten wir Sie hiermit um Erlaubnis, die folgenden Technologien zu verwenden: {purposes}. Sie können Ihre Einwilligung später zu jederzeit ändern/widerrufen (Cookie-Einstellungen)',
        learnMore: 'mehr Informationen',
      },
      acceptSelected: 'Accept selection',
      googleAnalytics: {
        description:
          'Open source web analysis tool'
      },
      purposes: {
        analytics: 'Analytics',
        security: 'Security',
        livechat: 'Livechat',
        advertising: 'Advertising',
        styling: 'Styling',
        convert: 'Reading function for text',
        video: 'Abspielen von Videos',
        recaptcha: 'Vermeidung von Spam',
        map: 'Kartenanzeige',
        fonts: 'Schriftdarstellung',
        cookie: 'Speicherung von Einstellungen dieser Anwendung',
      },
      reCaptcha: {
        description: 'External tool for form processing (Provider: Google)',
      },
      youtube: {
        description: 'External video player (provider: Youtube)',
      },
      googleMaps: {
          description: 'Map display (Provider: Google)',
      },
    },
  },

  // This is a list of third-party apps that Klaro will manage for you.
  apps: [
    {
      name: 'klaro',
      title: 'Klaro! Consent Manager',
      purposes: ['cookie'],
      required: true,
    },
    {
      name: 'googleAnalytics',
      default: false,
      title: 'Google Analytics',
      purposes: ['analytics'],
      cookies: [
        [/^_g/, '/', '.schulenfuersozialeberufe.de']
      ],
    },
    {
      name: 'googleMaps',
      title: 'Google Maps',
      purposes: ['map'],
      required: false,
    },
    {
      name: 'youtube',
      title: 'Youtube',
      purposes: ['video'],
      required: false,
    },
  ],
};